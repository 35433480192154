@charset "utf-8";

/**
 Table of Contents:

1. DEFAULT CLASSES
2. ELEMENTS
  2.1.  HEADER
  2.2.  MENUS
  2.3.  LOGO
  2.4.  SECTION
  2.5.  PROJECT
  2.6.  ICON BOXES
  2.7.  GALLERY SLIDER
  2.8.  TESTIMONIALS
  2.9.  BLOG
  2.10. NEWSLETTER
  2.11. FOOTER
  2.12. SERVICES
  2.13. FILM PROJECTS
  2.14. COUNTER
  2.15. TEAM
  2.16. FILM MENU
  2.17. BLOGGER
  2.18. SIDEBAR
  2.19. FILM ONEPAGE
  2.20. PAGE TITLE
  2.21. GALLERY
  2.22. 404
  2.23. CONTACT
  2.24. COMMENT
  2.25. PROGRESS BARS
3. COMMERCE
4. RESPONSIVE

*
* -----------------------------------------------------------------------------
*/

@font-face {
    font-family: 'ninestudio';
    src: url(../fonts/ninestudio.eot);
    src: url(../fonts/ninestudio.eot?#iefix) format("embedded-opentype"), url(../fonts/ninestudio.woff) format("woff"), url(../fonts/ninestudio.ttf) format("truetype"), url(../fonts/ninestudio.svg#star) format("svg");
    font-weight: 400;
    font-style: normal
}

[class^="ns-"], [class*=" ns-"] {
    font-family: 'ninestudio' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.ns-d-glasses:before{
	content:"\e900"
}
.ns-award:before{
	content:"\e901"
}
.ns-cinema-screen:before{
	content:"\e902"
}
.ns-film-strip:before{
	content:"\e903"
}
.ns-film:before{
	content:"\e904"
}
.ns-illumination:before{
	content:"\e905"
}
.ns-picture:before{
	content:"\e906"
}
.ns-popcorn:before{
	content:"\e907"
}
.ns-projector-screen:before{
	content:"\e908"
}
.ns-seats:before{
	content:"\e909"
}
.ns-television:before{
	content:"\e90a"
}
.ns-theater:before{
	content:"\e90b"
}
.ns-tickets:before{
	content:"\e90c"
}
.ns-tripod:before{
	content:"\e90d"
}
.ns-video-camera:before{
	content:"\e90e"
}

/* --------------------------------------------------------------------- */


/* 1. DEFAULT CLASSES
/* --------------------------------------------------------------------- */

* {
	padding: 0;
	margin: 0;
}
*, :active, :focus, :hover {
    outline: 0!important;
}
body {
    font-family: Lato,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0;
    color: #696969;
    font-size: 15px;
}
.bg-body {
	background: url(../images/background/bg_body.jpg);
}
ul, ol {
    list-style: none;
}
a {
	color: #696969;
    text-decoration: none;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
a:hover {
	text-decoration: none;
	color: #495056;
}
a:focus {
	text-decoration: none;
}
blockquote {
    font-size: 20px;
    font-style: italic;
    color: #ababab;
    font-weight: 400;
    padding: 20px 60px;
    margin: 0;
	border: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: "";
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
iframe {
	border: none;
	width: 100%;
}
button, input, select, textarea {
    font-size: 15px;
    color: #404040;
    background-color: #f4f4f4;
    font-family: Lato;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
    border: 1px solid #f1eeea;
    border-radius: 3px;
    color: #666;
    padding: 7px 15px;
    outline: 0;
    width: 100%;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"] {
    height: 40px;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
    font-weight: 700;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0;
    color: #333;
	margin-top: 0;
}
h1, .h1 {
	font-size:56px;
}
h2, .h2 {
    font-size: 40px;
}
h3, .h3 {
    font-size: 34px;
}
h4, .h4 {
    font-size: 24px;
}
h5, .h5 {
    font-size: 18px;
}
h6, .h6 {
    font-size: 14px;
}
.site {
    overflow: hidden;
	position: relative;
    z-index: 1;
	-webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.site.open {
    transform: translateX(256px);
}
.site.open:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #000;
    opacity: .5;
    z-index: 9999;
}
.button {
    display: inline-block;
    background-color: #495056;
    color: #fff;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .05em;
    line-height: 46px;
    height: 48px;
    border: 1px solid #495056;
    padding: 0 20px;
}
.button:hover {
    background-color: transparent;
    color: #495056;
}
.button-2 {
    display: inline-block;
    background-color: transparent;
    color: #495056;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .05em;
    line-height: 46px;
    height: 48px;
    border: 1px solid #495056;
    padding: 0 40px;
}
.button-2:hover {
    background-color: #495056;
    color: #fff;
}
.top-search {
    width: 100%;
    background-color: #495056;
    color: #fff;
    display: none;
}
.top-search form {
    padding-left: 30px;
    position: relative;
}
.top-search form:before {
    content: '\f4a5';
    font-family: Ionicons;
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    left: 0;
    height: 40px;
    line-height: 40px;
}
.top-search form input[type="search"] {
    border: none;
    background: none;
    color: #fff;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0;
}
.top-search form input[type="search"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #fff;
}
.top-search form input[type="search"]::-moz-placeholder { /* Firefox 19+ */
	color: #fff;
}
.top-search form input[type="search"]:-ms-input-placeholder { /* IE 10+ */
	color: #fff;
}
.top-search form input[type="search"]:-moz-placeholder { /* Firefox 18- */
	color: #fff;
}
.popup-video {
	overflow: hidden;
	margin-top: -80px;
    margin-bottom: 80px;
	position: relative;
}
.popup-video img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    z-index: 96;
}
.popup-video:hover img {
    transform: scale(1.1);
}
.popup-video a:before {
    content: '';
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: #495056;
    opacity: .3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 97;
}
.popup-video a:after {
    content: '\f215';
    font-family: Ionicons;
    color: #fff;
    font-size: 24px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    background-color: #495056;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 98;
}
.popup-video.style-2 {
	margin-top: -110px;
    margin-bottom: 50px;
	-moz-box-shadow: 0 0 20px rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,.5);
    box-shadow: 0 0 20px rgba(0,0,0,.5);
}
.film-button {
    display: inline-block;
    margin-right: 20px;
}
.film-button i {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    background-color: #495056;
    color: #fff;
    display: inline-block;
    margin-right: 20px;
    font-size: 20px;
    vertical-align: middle;
    -moz-box-shadow: 0 0 20px rgba(0,0,0,.2);
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,.2);
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-button:hover i {
    background-color: #333;
}
.film-button a {
    display: inline-block;
    vertical-align: middle;
    color: #333;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .05em;
    line-height: 1;
    padding-bottom: 5px;
}
a.backtotop {
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 24px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: #495056;
    position: fixed;
    right: 60px;
    cursor: pointer;
    opacity: 0;
    bottom: 0;
	z-index: 10;
}
a.backtotop.show {
    opacity: 1;
    bottom: 60px;
    color: #fff;
}
a.backtotop:before {
    content: '';
    width: 74px;
    height: 74px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #495056;
    opacity: .3;
    position: absolute;
    top: -9px;
    left: -9px;
    transform: scale(0.5);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
a.backtotop.show:hover:before {
    transform: scale(1);
}
body.open-right .site:before {
    content: '';
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: .5;
    z-index: 1000;
}
.featured-number {
	font-family: Montserrat,Helvetica,Arial,sans-serif;
    font-size: 150px;
    color: rgba(204,204,204,0.02);
    line-height: 1;
    font-weight: 700;
    text-transform: none;
	margin-left: -20px
}
.featured-title {
	font-family: Merriweather,Georgia,serif;
    font-size: 40px;
    color: #ffffff;
    font-weight: 400;
    text-transform: none;
	margin-top: -100px;
    margin-left: 110px;
}
.text-center .featured-title {
	margin-left: 240px;
}
.film-links-cloud {
	margin-top: 40px;
    margin-bottom: 70px;
	max-width: 530px;
}
.film-links-cloud ul {
    padding: 0;
}
.film-links-cloud ul li {
    display: inline;
}
.film-links-cloud ul li a {
    font-size: 24px;
    line-height: 48px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #ababab;
}
.film-links-cloud.dark ul li a {
    color: #696969;
}
.film-links-cloud ul li a:hover {
    color: #333;
}
.film-links-cloud.dark ul li a:hover {
    color: #fff;
}
.film-links-cloud ul li a:before {
    content: "\f111";
    font-family: FontAwesome;
    font-size: 6px;
    display: inline-block;
    line-height: 1;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    transform: translateY(-50%);
    color: #e2e2e2;
}
.film-links-cloud ul li:first-child a:before {
    content: "";
    margin-left: 0px;
    margin-right: 0px;
}
.film-links-cloud.dark ul li a:before {
    color: #696969;
}
.film-hire-box {
	color: #fff;
    background-color: #333;
    width: 100%;
    padding: 90px 90px 60px 90px;
    margin: 0;
    font-family: Montserrat;
    position: relative;
    overflow: hidden;
}
.film-hire-box .title {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #495056;
}
.film-hire-box .text {
    margin-top: 30px;
    /*font-family: Merriweather;*/
    font-size: 34px;
    font-weight: 400;
    line-height: 1.4;
}
.film-hire-box .link {
    margin-top: 80px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-hire-box .link a {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #495056;
    height: 48px;
    line-height: 46px;
    padding: 0 24px;
    border: 1px solid #495056;
}
.film-hire-box .link a:hover {
    border-color: #fff;
    color: #fff;
}
.film-hire-box.style-2 {
	background: none;
	padding: 0;
}
.film-hire-box.style-2:before {
    content: ' ';
    width: 2000px;
    height: 100%;
    background-color: #333;
    display: block;
    position: absolute;
    top: -70px;
    left: 50%;
}
.film-hire-box.style-2 .film-hire-box-right {
    color: #fff;
    width: 100%;
    padding: 70px 130px;
    margin: 0;
    position: relative;
    overflow: hidden;
}
.pagination {
    margin-top: 60px;
    text-align: center;
    text-transform: uppercase;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
	width: 100%;
}
.pagination a, .pagination span {
    width: 40px;
    padding: 0;
    height: 40px;
    line-height: 40px;
    margin: 0;
    font-weight: 700;
    display: inline-block;
}
.pagination .page-numbers.current {
    border-radius: 50%;
    height: 40px;
	color: #495056;
}
.pagination a {
    font-size: 14px;
    border: 0;
}
.pagination a.prev, .pagination span.prev {
    border: 0;
    margin-right: 10px;
    width: auto;
}
.pagination a.prev:before, .pagination span.prev:before {
    content: '\f3d2';
    font-family: Ionicons;
    margin-right: 10px;
}
.pagination a.next, .pagination span.next {
    border: 0;
    margin-left: 10px;
    width: auto;
}
.pagination a.next:after, .pagination span.next:after {
    content: '\f3d3';
    font-family: Ionicons;
    margin-left: 10px;
}
.film-social li {
    margin-right: 10px;
	position: relative;
    display: inline-block;
}
.film-social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    border: 1px solid #f4f4f4;
    border-radius: 20px;
    font-size: 14px;
    color: #696969;
}
.film-social li:hover > a {
    background-color: #495056;
	color: #fff;
}
.film-social .tooltip.top .tooltip-arrow {
    border-top-color: #495056;
}
.film-social .tooltip-inner {
    background-color: #495056;
}
.image-shadow {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
	background-color: #f0f0f0;
}
.image-shadow img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
	-moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
	transform: translate(40px,60px);
}
.hashtag {
    font-size: 80px;
    font-weight: 700;
    color: #333;
    /*font-family: Merriweather;*/
    line-height: 1;
    position: absolute;
    top: -40px;
    left: 100px;
}
.image-box-shadow {
	width: 100%;
    height: auto;
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
}
#googleMap {
	min-height: 480px;
}
.box-shadow {
	-moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
}
.floating {
    -webkit-animation-name: floating;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: floating;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
}
@-webkit-keyframes floating {
    from {-webkit-transform:translate(0, 0px);}
    65% {-webkit-transform:translate(0, 15px);}
    to {-webkit-transform: translate(0, -0px);    }    
}
@-moz-keyframes floating {
    from {-moz-transform:translate(0, 0px);}
    65% {-moz-transform:translate(0, 15px);}
    to {-moz-transform: translate(0, -0px);}    
}

/* --------------------------------------------------------------------- */
/* 2. ELEMENTS
/* --------------------------------------------------------------------- */

/* === 2.1. HEADER === */
.header {
    width: 100%;
	margin-top: 0;
    margin-bottom: 0;
    position: relative;
    z-index: 99;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.header.header-overlay {
    background-color: transparent;
    position: absolute;
}
.header.header-1,
.header.header-3 {
    position:fixed;
    display: inline-block;
    width: 100%;
	min-height: 101px;
}
.header.header-1 .header-left,
.header.header-3 .header-left {
    padding-left: 100px;
}
.header.header-1 .header-right,
.header.header-3 .header-right {
    padding-right: 100px;
    /*padding-right: 10px;*/
    text-align: right;
    position: static;
}
.header.header-1 .logo,
.header.header-3 .logo {
    margin-top: 20px;
    max-height:80px;
}
.header.header-1 .logo-alt,
.header.header-3 .logo-alt {
    display: none;
	margin-top: 22px;
    max-height:80px;
}
.header.header-1 .header-right .open-search,
.header.header-3 .header-right .open-search {
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    margin-left: 20px;
    cursor: pointer;
    color: #333;
}
.header.header-overlay .header-right .open-search {
    color: #fff;
}
.header.header-overlay.scrolling-menu .menu-primary > ul > li > a,
.header.header-overlay.scrolling-menu .header-right .open-search {
    color: #333;
}
.header.scrolling-menu .logo {
    display: none;
}
.header.scrolling-menu .logo-alt {
    display: inline-block;
}
.header.header-2 {
	position: absolute;
    z-index: 999;
	padding: 20px 0;
	display: inline-block;
    width: 100%;
	background-color: transparent;
}
.header.header-2 .hamburger-menu {
    display: inline-block;
    font-size: 32px;
    color: #fff;
    cursor: pointer;
}
.header.scrolling-menu {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.05);
    z-index: 999;
}
.header.header-2.scrolling-menu {
    padding: 0;
}
.header.header-2.scrolling-menu .hamburger-menu {
    color: #696969;
}
.header.header-3 {
    background-color: #fff;
}
.header.header-3 .hamburger-menu {
    color: #333;
	display: inline-block;
    font-size: 32px;
	cursor: pointer;
	margin-top: 20px;
}
header.header-mobile {
    display: none;
    padding: 30px 0;
    width: 100%;
}
header.header-mobile .header-left {
    font-size: 35px;
    text-align: left;
}
header.header-mobile .header-left i {
    color: #5e5a54;
}
header.header-mobile .header-center {
	text-align: center;
}
header.header-mobile .header-center .logo-image {
	margin-top: 10px;
}
header.header-mobile .header-right {
	text-align: right;
	font-size: 28px;
}
header.header-mobile .header-right .open-search {
    margin-top: 10px;
}

/* === 2.2. MENUS === */
#menu {
    position: static;
}
.header .menu {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0;
    font-size: 14px;
}
.header.header-1 .header-right nav.menu,
.header.header-3 .header-right nav.menu {
    display: inline-block;
    text-align: right;
}
.header .menu-primary > ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    position: static;
    z-index: 2;
}
.header .menu-primary > ul > li {
    position: relative;
    display: inline-block;
}
.header.header-overlay .menu-primary > ul > li > a {
    color: #000;
}
.header .header-right .menu-primary > ul > li > a {
    text-transform: uppercase;
    position: relative;
	display: inline-block;
	padding-top: 40px;
    padding-bottom: 40px;
    /*padding-left: 20px;*/
    padding-right: 20px;
}
.header .header-right .menu-primary > ul li.active > a {
	color: #495056;
}
.header .menu-primary > ul > li.dropdown > a:after {
    content: '\f078';
    margin-left: 10px;
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.header .menu-primary .sub-menu {
    font-size: 15px;
	position: absolute;
    top: 110%;
    left: 0;
    visibility: hidden;
    margin: 0;
    min-width: 230px;
    width: 100%;
    background-color: #fff;
    opacity: 0;
    transition: all .3s ease;
    padding: 20px 0;
    list-style: none;
    text-align: left;
    text-transform: none;
    -moz-box-shadow: 5px 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 5px 10px 20px rgba(0,0,0,.1);
    box-shadow: 5px 10px 20px rgba(0,0,0,.1);
	border-top: 2px solid #495056;
}
.header .menu-primary > ul > li:hover > .sub-menu {
    top: 100%;
    visibility: visible;
    z-index: 2;
    opacity: 1;
}
.header .menu-primary .sub-menu li {
    position: relative;
    padding: 0 20px;
}
.header .menu-primary .sub-menu li a {
    display: block;
    padding: 15px 0;
    color: #696969;
    text-transform: none;
    font-weight: 400;
    background: none;
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    white-space: normal;
}
.header .menu-primary .sub-menu li a:hover {
    border-bottom-color: #495056;
	color: #495056;
}
.header .menu-primary .sub-menu li.dropdown:after {
    content: '\f363';
    font-size: 16px;
    font-family: Ionicons;
    position: absolute;
    right: 20px;
    top: 14px;
    color: #696969;
}
.header .menu-primary .sub-menu li.dropdown:hover:after {
    color: #495056;
}
.header .menu-primary > ul > li > .sub-menu li > .sub-menu {
    top: 0;
    left: 100%;
    visibility: hidden;
    opacity: 0;
}
.header .menu-primary > ul > li:hover > .sub-menu li:hover > .sub-menu {
    top: -22px;
    left: 100%;
    visibility: visible;
    opacity: 1;
    border-top: 2px solid #495056;
}
.header.header-overlay.header-3 .header-right .open-search,
.header.header-overlay.header-3 .menu-primary > ul > li > a {
    color: #333;
}
.header.header-overlay.header-3 .header-right .open-search:hover,
.header.header-overlay.header-3 .menu-primary > ul > li > a:hover {
    color: #495056;
}
.open-right-content {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    position: fixed;
    width: 90%;
    max-width: 640px;
    right: -100%;
    top: 0;
    bottom: 0;
    padding: 40px 100px;
    z-index: 1001;
    background: #262626;
    color: #fff;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.open-right-content.open {
	right: 0;
}
.open-right-content .open-right-close {
    display: block;
    text-align: right;
    line-height: 1;
    font-size: 32px;
    cursor: pointer;
}
.open-right-content ul {
    margin: 100px 0 0 0;
    padding: 0;
    list-style: none;
}
.open-right-content ul li {
    display: block;
    padding: 20px 0;
}
.open-right-content ul li a {
    color: #696969;
    font-size: 56px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    line-height: 1;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.open-right-content ul li a:hover {
    opacity: 1;
    color: #495056;
}
.open-right-content .search-form {
    display: block;
    margin-top: 100px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid rgba(255,255,255,.3);
    position: relative;
}
.open-right-content .search-form form {
    position: relative;
    height: 32px;
    line-height: 32px;
}
.open-right-content .search-form form input[type="text"], 
.open-right-content .search-form form input[type="search"] {
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    font-family: Montserrat;
    color: #fff;
}
.open-right-content .search-form form input[type="submit"] {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 0;
    vertical-align: top;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    z-index: 8;
}
.open-right-content .search-form form:after {
    content: '\f4a4';
    font-family: Ionicons;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 7;
}
.slideout-menu {
	background: #fff;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.slideout-menu.open {
    display: block;
}
.mobile-menu > ul.menu {
    padding: 0;
    margin: 0;
}
.mobile-menu > ul.menu li {
    position: relative;
    display: block;
    border-bottom: 1px solid #eee;
}
.mobile-menu > ul.menu li.expand {
    background-color: #ddd;
}
.mobile-menu > ul.menu li a {
    display: block;
    color: #5e5a54;
    padding: 9px 20px;
    background: inherit;
}
.mobile-menu>ul.menu li .sub-menu-toggle {
    display: none;
}
.mobile-menu > ul.menu li.dropdown > .sub-menu-toggle {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 45px;
    line-height: 42px;
    text-align: center;
    outline: 0;
    background-color: rgba(238,238,238,.5);
    cursor: pointer;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.mobile-menu > ul.menu li.expand > .sub-menu-toggle {
	background-color: #495056;
	color: #fff;
}
.mobile-menu > ul.menu li.expand > .sub-menu-toggle:before {
	content: "\f106";
}
.mobile-menu > ul.menu ul, 
.mobile-menu > ul.menu ol {
    padding: 0;
    margin: 0;
}
.mobile-menu > ul.menu li .sub-menu {
    display: none;
    border-top: 1px solid #ddd;
    background-color: #eee;
    padding-left: 0;
    font-weight: 400;
    box-shadow: none;
    -moz-box-shadow: none;
}
.mobile-menu > ul.menu li .sub-menu li {
    border: 0;
}
.mobile-menu > ul.menu li .active a {
    box-shadow: 1px 0 0 #111 inset;
}
.mobile-menu > ul.menu li .sub-menu li a {
    padding-left: 35px;
}
.mobile-menu > ul.menu li .sub-menu li .sub-menu li a {
    padding-left: 55px;
}

/* === 2.3. LOGO === */
.logo-carousel {
	margin-bottom: 30px;
}
.logo-carousel .logo-item img {
	display: block;
    margin: 0 auto;
    opacity: .2;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.logo-carousel .logo-item:hover img {
    opacity: 1;
}
.logo-carousel.no-opacity .logo-item img {
	opacity: 1;
}
.logo-carousel.no-opacity .logo-item:hover img {
    transform: scale(1.1);
}

/* === 2.4. SECTION === */
.section.border-bottom {
	border-bottom: 1px solid #eee;
}
.section.border-top {
	border-top: 1px solid #eee;
}
.section-title {
	font-size: 34px;
    color: #333333;
    line-height: 1.4em;
    /*font-family: Merriweather;*/
    font-weight: 400;
    font-style: normal;
    text-transform: none;
}
.section-title.small {
	font-size: 24px;
    line-height: 40px;
}
.section-title.bottom-line:after {
    content: '';
    display: block;
    width: 30px;
    height: 1px;
    background-color: #495056;
    margin-top: 5px;
}
.sub-title {
    font-size: 14px;
    letter-spacing: .1em;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 22px;
}
.section {
    position: relative;
    width: 100%;
    z-index: 1;
}
.section-fixed {
	background-attachment: fixed;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}
.section-cover {
	background-repeat: no-repeat;
	background-size: cover;
}
.section-bg-1 {
	background-image:url(../images/background/bg_1920x560.jpg);
	background-position: center;
}
.section-bg-2 {
	background-image:url(../images/background/bg_1920x496.jpg);
	background-position: center top;
	background-repeat: no-repeat;
}
.section-bg-3 {
	background-image:url(../images/background/bg_1920x330.jpg);
	background-position: center;
}
.section-bg-4 {
	background-image:url(../images/background/bg_1920x880.jpg);
	background-position: center;
}
.section-bg-5 {
	background-image:url(../images/background/bg_1920x1766.jpg);
	background-position: center;
}
.section-bg-6 {
	background-image:url(../images/background/bg_6.png);
	background-position: center bottom;
	background-repeat: no-repeat;
}
.section-bg-7 {
	background-image:url(../images/background/bg_1920x495.jpg);
	background-position: center;
}
.section-bg-8 {
	background-image:url(../images/background/bg_1920x744.jpg);
	background-position: center;
}
.section-bg-9 {
	background-image:url(../images/background/bg_1920x330.jpg);
	background-position: center;
}
.section-bg-10 {
	background-image:url(../images/background/bg_13.png);
	background-position: center;
	background-repeat: no-repeat;
}
.section-bg-11 {
	background-image:url(../images/background/bg_1920x770.jpg);
	background-position: center;
}
.section-bg-12 {
	background-image:url(../images/background/bg_1920x460.jpg);
	background-position: center;
}
.section-bg-13 {
	background-image:url(../images/background/bg_18.jpg);
	background-position: center;
}
.section-404 {
	background-image:url(../images/background/bg_16.jpg);
	background-position: center;
}

/* === 2.5. PROJECT === */
.film-project-featured {
	display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}
.film-project-featured .image {
    position: relative;
    overflow: hidden;
    z-index: 7;
}
.film-project-featured .image:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 7;
    opacity: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-project-featured:hover .image:before {
    opacity: 1;
}
.film-project-featured .image img {
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-project-featured .info {
    position: absolute;
    top: 60%;
    left: 0;
    display: block;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    z-index: 8;
    opacity: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-project-featured:hover .info {
    top: 50%;
    opacity: 1;
}
.film-project-featured .info .category {
	color: #fff;
}
.film-project-featured .info .category a {
    font-size: 14px;
    /*font-family: Merriweather;*/
    font-style: italic;
    color: #fff;
    font-weight: 400;
}
.film-project-featured .info .category a:hover {
    color: #495056;
}
.film-project-featured .info .title {
    font-size: 24px;
    /*font-family: Merriweather;*/
    color: #fff;
    font-weight: 400;
    margin-bottom: 90px;
}
.film-project-featured .info .more a {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #495056;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 20px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    line-height: 1;
}
.film-project-featured .info .more a:hover {
    border-color: #495056;
}

/* === 2.6. ICON BOXES === */
.icon-boxes .icon-boxes-icon {
	color: #495056;
    font-size: 105px;
    padding-top: 50px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}
.icon-boxes .icon-boxes-icon i {
    padding: 8px;
    border-radius: 50%;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.icon-boxes .icon-boxes-title {
	font-family: Montserrat,Helvetica,Arial,sans-serif;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
	letter-spacing: .05em;
    font-size: 14px;
	color: #333;
}
.icon-boxes .icon-boxes-content {
    color: #696969;
}
.icon-boxes .icon-boxes-link {
    margin-top: 20px;
}
.icon-boxes .icon-boxes-link a {
    font-size: 18px;
    font-weight: 700;
    color: #495056;
    font-style: italic;
    padding-left: 4px;
    letter-spacing: .1em;
    line-height: 34px;
    border: 2px solid #f4f4f4;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}
.icon-boxes:hover .icon-boxes-link a {
    border-color: #495056;
    background-color: #495056;
    color: #fff;
}
.icon-boxes-shadow .icon-boxes-icon {
	width: 170px;
    height: 170px;
    line-height: 170px;
    text-align: center;
    display: inline-block;
    color: #495056;
    font-size: 60px;
    margin-bottom: 30px;
	padding-top: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.icon-boxes-shadow:hover .icon-boxes-icon {
    background-color: #495056;
    color: #fff;
}
.icon-boxes-small .icon-boxes-icon {
    font-size: 14px;
    color: #495056;
	display: inline-block;
	padding-top: 0;
}
.icon-boxes-small .icon-boxes-title {
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: .05em;
    font-size: 14px;
    font-weight: 700;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
	display: inline-block;
	text-transform: uppercase;
}

/* === 2.7. GALLERY SLIDER === */
.film-gallery-carousel .film-gallery-carousel-item {
    outline: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-gallery-carousel .film-gallery-carousel-item.slick-slide {
    width: 840px;
}
.film-gallery-carousel .film-gallery-carousel-item .film-gallery-carousel-item-inner {
    display: block;
    width: 100%;
    background-color: #696969;
    transform: scale(0.5);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-gallery-carousel .film-gallery-carousel-item.slick-slide.slick-center .film-gallery-carousel-item-inner {
    transform: scale(1);
    -moz-box-shadow: 0 0 20px rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,.5);
    box-shadow: 0 0 20px rgba(0,0,0,.5);
}
.film-gallery-carousel .film-gallery-carousel-item.slick-slide img {
    width: 100%;
    opacity: .5;
}
.film-gallery-carousel .film-gallery-carousel-item.slick-slide.slick-center .film-gallery-carousel-item-inner img {
    opacity: 1;
}
.film-gallery-carousel .film-gallery-carousel-item .info {
    text-align: center;
    margin-top: 50px;
    opacity: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-gallery-carousel .film-gallery-carousel-item.slick-slide.slick-center .info {
    opacity: 1;
}
.film-gallery-carousel .film-gallery-carousel-item .info .title {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.film-gallery-carousel .film-gallery-carousel-item .info .title a {
    font-size: 18px;
    line-height: 36px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: .2em;
}
.film-gallery-carousel .film-gallery-carousel-item .info .title a:hover {
    color: #495056;
}
.film-gallery-carousel .film-gallery-carousel-item .info .category {
    font-family: Merriweather,Georgia,serif;
	font-size: 14px;
    color: #696969;
    line-height: 36px;
    font-style: italic;
}
.film-gallery-carousel .film-gallery-carousel-item .info .category a {
    margin-left: 5px;
    margin-right: 5px;
}

/* === 2.8. TESTIMONIALS === */
.testimonial-carousel.style-1 .item {
    width: 100%;
    /*text-align: center;*/
    outline: 0;
    z-index: 98;
}
.testimonial-carousel.style-1 .item .photo {
    height: 135px;
    /*height: 80px;*/
    display: inline-block;
    margin: 0 10px;
    margin-bottom: 30px;
}
.testimonial-carousel.style-1 .item .photo img {
    width:auto;
    height:150px;
    /*width: 80px;*/
    /*height: 80px;*/
    /*border-radius: 40px;*/
	display: block;
}
.testimonial-carousel.style-1 .item .title {
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 35px;
    font-family: Montserrat;
    max-width:90%;
    color: #333;
}
.testimonial-carousel.style-1 .item .text {
    width: 80%;
    max-width: 750px;
    /*margin: 0 auto;*/
    font-size: 18px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 2;
    color: #696969;
}
.testimonial-carousel.style-1 .item .info {
    margin-top: 30px;
    display: inline-block;
}
.testimonial-carousel.style-1 .item .info .author {
    display: inline-block;
    margin: 0 10px;
    text-align: left;
    font-family: Montserrat;
}
.testimonial-carousel.style-1 .item .info .author .name,
.testimonial-carousel.style-1 .item .info .author .tagline {
    display: inline-block;
    letter-spacing: .05em;
}
.testimonial-carousel.style-1 .item .info .author .name {
    font-size: 14px;
    text-transform: uppercase;
    color: #696969;
    font-weight: 700;
}
.testimonial-carousel.style-1 .item .info .author .tagline {
    font-size: 14px;
    color: #ccc;
}
/*.testimonial-carousel.style-1 .item .info .author .name:after {*/
    /*content: '/';*/
    /*margin-left: 8px;*/
    /*margin-right: 8px;*/
/*}*/
.film-single-testimonial {
    margin-bottom: 0;
    width: 100%;
    outline: 0;
}
.film-single-testimonial .item {
    width: 100%;
    text-align: center;
    outline: 0;
    z-index: 98;
}
.film-single-testimonial .item .photo {
    height: auto;
    display: inline-block;
    margin: 0 0 55px 0;
}
.film-single-testimonial .item .photo img {
    max-width: 100%;
    height: auto;
}
.film-single-testimonial .item .text {
    width: 80%;
    max-width: 750px;
    margin: 0 auto;
    /*font-family: Merriweather;*/
    font-size: 24px;
    font-style: italic;
    letter-spacing: 0;
    line-height: 2;
    color: #333;
}
.film-single-testimonial .item .sign {
    margin-top: 55px;
}
.film-single-testimonial .item .info {
    margin-top: 30px;
    display: inline-block;
}
.film-single-testimonial .item .info .author {
    display: inline-block;
    margin: 0 10px;
    text-align: left;
    font-family: Montserrat;
}
.film-single-testimonial .item .info .author .name, 
.film-single-testimonial .item .info .author .tagline {
    display: inline-block;
    letter-spacing: .05em;
}
.film-single-testimonial .item .info .author .name {
    font-size: 14px;
    text-transform: uppercase;
    color: #696969;
    font-weight: 700;
}
.film-single-testimonial .item .info .author .tagline {
    font-size: 14px;
    color: #ccc;
}
.film-single-testimonial .item .info .author .tagline:before {
    content: '/';
    margin-left: 8px;
    margin-right: 8px;
}
.film-single-testimonial.small .item .photo {
    height: auto;
    margin-bottom: 40px;
}
.film-single-testimonial.small .item .text {
    font-size: 16px;
    color: #696969;
}

/* === 2.9. BLOG === */
.blog-item .blog-item-inner {
    padding: 30px;
    min-height: 205px;
    background-color: #fff;
    position: relative;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.blog-item .blog-item-inner .meta {
    margin-top: 40px;
    color: #696969;
}
.blog-item .blog-item-inner .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.blog-item .blog-item-inner .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.blog-item .blog-item-inner .meta>span a {
    color: #696969;
}
.blog-item .blog-item-inner .blog-info .blog-info-meta .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.blog-item.style-1 {
	margin-bottom: 30px;
}
.blog-item.style-1 .blog-item-inner {
    padding: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.blog-item.style-1 .blog-item-inner:hover {
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.05);
}
.blog-item.style-1 .blog-item-inner .blog-thumbnail {
    overflow: hidden;
}
.blog-item.style-1 .blog-item-inner .blog-thumbnail img {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.blog-item.style-1 .blog-item-inner:hover .blog-thumbnail img {
    transform: scale(1.1);
}
.blog-item.style-1 .blog-item-inner .blog-info {
    padding: 30px;
    background-color: #fff;
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-date, 
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-title, 
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-meta {
    display: block;
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-date {
    font-size: 14px;
    font-family: Montserrat;
    color: #ccc;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 30px;
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-title {
    font-size: 18px;
    /*font-family: Merriweather;*/
    /*font-weight: 400;*/
    color: #333;
    /*margin-bottom: 70px;*/
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-title:hover {
    color: #495056;
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-meta .meta {
    font-size: 14px;
}
.blog-item.style-1 .blog-item-inner .blog-info .blog-info-meta .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-home-blog .blog-wrap {
    background-color: #f6f7f7;
    overflow: hidden;
}
.film-home-blog .blog-wrap .col-image {
    position: relative;
}
.film-home-blog .blog-wrap .col-image .image {
    z-index: 96;
    overflow: hidden;
}
.film-home-blog .blog-wrap .col-image .image img {
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-home-blog .blog-wrap:hover .col-image .image img {
    transform: scale(1.1);
}
.film-home-blog .blog-wrap .col-text {
    padding-top: 30px;
    padding-bottom: 30px;
}
.film-home-blog .blog-wrap .time {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    color: #ccc;
    text-transform: uppercase;
}
.film-home-blog .blog-wrap .title {
    font-size: 18px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #333;
    line-height: 28px;
    margin-top: 20px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-home-blog .blog-wrap:hover .col-text .title {
    color: #495056;
}
.film-home-blog .blog-wrap .meta {
    margin-top: 20px;
    color: #696969;
    font-size: 14px;
}
.film-home-blog .blog-wrap .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-home-blog .blog-wrap .meta > span:last-child {
    margin-right: 0;
}
.film-home-blog .blog-wrap .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-home-blog .blog-wrap .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-home-blog .blog-wrap .col-quote {
    position: relative;
    background-image: url(../images/quote_bg_1.png);
    background-repeat: no-repeat;
    background-position: 100% 110%;
}
.film-home-blog .blog-wrap .col-quote img {
    z-index: 96;
    opacity: 0;
}
.film-home-blog .blog-wrap .col-quote .text {
    padding: 30px;
    position: absolute;
    z-index: 97;
    top: 0;
}
.film-home-blog .blog-wrap:hover .col-quote .text .title {
    color: #495056;
}
.film-home-blog .blog-wrap .col-quote .text .title:before {
    content: '«';
    display: inline-block;
    margin-right: 10px;
}
.film-home-blog .blog-wrap .col-quote .text .title:after {
    content: '»';
    display: inline-block;
    margin-left: 10px;
}
.film-home-blog .blog-wrap .col-image .video-play {
    z-index: 97;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.film-home-blog .blog-wrap .col-image .video-play:before {
    content: '';
    width: 70px;
    height: 70px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    background-color: #495056;
    opacity: .3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 97;
}
.film-home-blog .blog-wrap .col-image .video-play:after {
    content: '\f215';
    font-family: Ionicons;
    color: #fff;
    font-size: 18px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-color: #495056;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 98;
}
.film-home-blog .blog-wrap.blog-1 {
    margin-bottom: 30px;
}
.film-home-blog .blog-wrap.blog-1 .col-image .image {
    position: relative;
}
.film-home-blog .blog-wrap.blog-1 .col-image .image:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: 15px solid #f6f7f7;
    border-right: 15px solid #f6f7f7;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
}
.film-home-blog .blog-wrap.blog-1 .col-text {
    padding-right: 30px;
}
.film-home-blog .blog-wrap.blog-2 {
    height: 100%;
}
.film-home-blog .blog-wrap.blog-2 .col-image .image:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #f6f7f7;
    border-right: 15px solid #f6f7f7;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 8;
}
.film-home-blog .blog-wrap.blog-2 .col-text {
    padding: 30px 45px;
}
.film-home-blog .blog-wrap.blog-4 .col-image img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.blog-carousel .blog-item {
	padding-left: 30px;
	padding-right: 30px;
}
.blog-item.style-2 {
    margin-top: 50px;
}
.blog-item.style-2 .blog-item-inner {
    box-shadow: 5px 5px 20px #eee;
	padding: 0;
}
.blog-item.style-2 .blog-thumbnail {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}
.blog-item.style-2 .blog-thumbnail:after,
.blog-item.style-2 .blog-thumbnail:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 97;
    top: 0;
    background-color: rgba(0,0,0,.2);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    opacity: 0;
}
.blog-item.style-2 .blog-thumbnail:before {
    left: -100%;
}
.blog-item.style-2 .blog-thumbnail:after {
    right: -100%;
}
.blog-item.style-2:hover .blog-thumbnail:before {
    left: 0;
    opacity: 1;
}
.blog-item.style-2:hover .blog-thumbnail:after {
    right: 0;
    opacity: 1;
}
.blog-item.style-2 .blog-thumbnail > a:before {
    z-index: 98;
    content: '\f489';
    font-family: Ionicons;
    font-size: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0;
    margin-left: -20px;
    opacity: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.blog-item.style-2:hover .blog-thumbnail > a:before {
    opacity: 1;
    margin-top: -20px;
}
.blog-item.style-2 .blog-thumbnail img {
    width: 100%;
}
.blog-item.style-2 .film-list-categories {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
.blog-item.style-2 .quote .film-list-categories {
    position: static;
	text-align: right;
}
.blog-item.style-2 .film-list-categories a {
    font-size: 12px;
    color: #fff;
    letter-spacing: .1em;
    background-color: #495056;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-item.style-2 .blog-info {
    padding: 0 30px 25px 30px;
}
.blog-item.style-2 .blog-info-date {
    margin-bottom: 10px;
    display: block;
    font-size: 12px;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-family: Montserrat;
	position: relative;
}
.blog-item.style-2 .blog-info-date .posted-on {
    margin-right: 20px;
    color: #ccc;
}
.blog-item.style-2 .blog-info-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0px;
    color: #333;
    position: relative;
    font-weight: 400;
}
.blog-item.style-2 .blog-item-inner .meta {
    margin-top: 20px;
}
.blog-list-item {
    /*margin-bottom: 50px;*/
    border-bottom: 1px solid #f4f4f4;
    /*padding-bottom: 50px;*/
    padding: 50px 0;
    background:#fff;
}
.blog-list-item .post-thumbnail a {
    display: block;
}
.blog-list-item .post-thumbnail a img {
    width: 100%;
}
.blog-list-item .list-categories a {
    font-size: 12px;
    color: #fff;
    letter-spacing: .1em;
    background-color: #495056;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
}
.blog-list-item .entry-desc {
    padding-top: 28px;
}
.blog-list-item .entry-meta {
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
}
.blog-list-item .entry-meta span {
    margin-right: 20px;
    color: #ccc;
}
.blog-list-item .entry-title {
    margin-bottom: 10px;
    color: #333;
    position: relative;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
	-webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.blog-list-item .entry-title:hover {
	color: #495056;
}
.blog-list-item .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.blog-list-item .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.blog-list-item .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.blog-list-item .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.blog-list-item .entry-content {
    font-size: 15px;
    color: #696969;
    line-height: 1.6;
}
.blog-list-item .entry-share {
    align-self: center;
    text-align: right;
}
.blog-list-item.style-1 .post-thumbnail {
    position: relative;
}
.blog-list-item.style-1 .post-thumbnail .list-categories {
    position: absolute;
    right: 20px;
    bottom: 20px;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-list-item.style-1 .entry-desc {
    padding-left: 50px;
}
.blog-list-item.style-1 .entry-title {
    margin-top: 15px;
}
.blog-list-item.style-1 .meta {
    margin-top: 90px;
}
.blog-list-item.style-2 .meta {
    margin-top: 10px;
	margin-bottom: 15px;
}
.blog-list-item.style-2 .entry-desc {
    padding-top: 0;
}
.blog-list-item.style-2 .list-categories {
    margin-top: -4px;
    margin-bottom: 10px;
}
.blog-list-item.style-3 .post-thumbnail {
    position: relative;
}
.blog-list-item.style-3 .post-thumbnail .list-categories {
    position: absolute;
    right: 20px;
    bottom: 20px;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-list-item.style-3 .entry-content {
    margin-top: 25px;
	margin-bottom: 25px;
}
.blog-detail .single-post-thumbnail {
    margin-bottom: 35px;
	position: relative;
}
.blog-detail .single-post-thumbnail .list-categories {
    position: absolute;
    right: 20px;
    bottom: 20px;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-detail .single-post-thumbnail .list-categories a {
    font-size: 12px;
    color: #fff;
    letter-spacing: .1em;
    background-color: #495056;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
}
.blog-detail .entry-title {
    margin-bottom: 15px;
    color: #333;
    letter-spacing: 0;
    font-weight: 400;
	font-size: 24px;
	font-family: Merriweather,Georgia,serif;
}
.blog-detail .meta {
    margin-bottom: 15px;
	margin-top: 20px;
}
.blog-detail .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.blog-detail .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.blog-detail .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.blog-detail .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.blog-detail .entry-footer {
    margin-bottom: 20px;
}
.blog-detail .tags {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-detail .tags .tag-icon {
    color: #ababab;
    font-size: 16px;
    margin-right: 15px;
}
.blog-detail .entry-footer .tags a {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    color: #ababab;
    margin-right: 5px;
    margin-bottom: 8px;
    display: inline-block;
}
.blog-detail .entry-footer .tags a:hover {
    color: #495056;
}
.blog-detail .entry-footer .entry-share {
    text-align: right;
}
.blog-detail .entry-author {
    padding: 40px 0;
    border-top: 1px solid #f4f4f4;
}
.blog-detail .entry-author img {
    border-radius: 50%;
    border: 5px solid #f2f2f2;
}
.blog-detail .entry-author .name {
    font-size: 14px;
    color: #333;
    letter-spacing: .05em;
    margin-bottom: 10px;
}
.blog-detail .entry-author .desc {
    font-size: 15px;
    line-height: 1.6em;
    color: #696969;
}
.blog-detail .entry-nav {
    padding: 40px 30px;
    background: #f8f7f7;
    border: 1px solid #f4f4f4;
    font-size: 18px;
    font-weight: 700;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.blog-detail .entry-nav .left i,
.blog-detail .entry-nav .right i {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    color: #ababab;
}
.blog-detail .entry-nav .left:hover i, 
.blog-detail .entry-nav .right:hover i {
    color: #495056;
}
.blog-detail .entry-nav a {
    text-transform: none;
    color: #ababab;
    outline: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    display: inline-block;
    vertical-align: middle;
}
.blog-detail .entry-nav .left:hover a, 
.blog-detail .entry-nav .right:hover a {
    color: #332b28;
}
.blog-detail .entry-nav .left a {
    padding-left: 10px;
}
.blog-detail .entry-nav .right a {
    padding-right: 10px;
}

/* === 2.10. NEWSLETTER === */
.footer-newsletter.style-1 .footer-newsletter-left {
    font-size: 34px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #333;
    line-height: 46px;
    text-transform: capitalize;
    margin-bottom: 30px;
}
.footer-newsletter.style-1 .footer-newsletter-right {
    margin-bottom: 30px;
}
.footer-newsletter.style-1 .footer-newsletter-right .form {
    position: relative;
    height: 48px;
    line-height: 48px;
    background-color: #f7f7f7;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.footer-newsletter.style-1 .footer-newsletter-right .form input[type="text"], 
.footer-newsletter.style-1 .footer-newsletter-right .form input[type="email"] {
    display: inline-block;
    background-color: transparent;
    color: #333;
    font-size: 14px;
    font-family: Montserrat;
    border: 0;
}
.footer-newsletter.style-1 .footer-newsletter-right .form input[type="submit"] {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 25px;
    background-color: #495056;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    position: absolute;
    top: 4px;
    right: 4px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.footer-newsletter.style-1 .footer-newsletter-right .form input[type="submit"]:hover {
    background-color: #333;
    color: #fff;
}
.footer-newsletter.style-1 .footer-newsletter-right p {
    color: #ababab;
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0;
}

/* === 2.11. FOOTER === */
.footer {
	display: block;
    width: 100%;
    padding-top: 80px;
    /*padding-bottom: 40px;*/
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
	background-color: #333;
    color: #ababab;
}
.footer .row > .col-md-4,
.footer .row > .col-md-2 {
	margin-bottom: 30px;
}
.footer a {
    color: #ababab;
}
.footer a:hover {
    color: #495056;
}
.footer .footer-social {
    margin-top:10px;
    /*margin-top: 30px;*/
    /*margin-bottom: 50px;*/
}
.footer .footer-social a {
	position: relative;
    display: inline-block;
    width: 48px;
    height: 48px;
    line-height: 44px;
    text-align: center;
    border: 2px solid #585858;
    border-radius: 24px;
    margin-right: 6px;
    font-size: 16px;
    color: #ababab;
}
.footer .footer-social a .fab{
    line-height:44px;
}

.footer .footer-social a:hover {
    background-color: #495056;
    border-color: #495056;
    color: #fff;
}
.footer .footer-social .tooltip.top .tooltip-arrow {
    border-top-color: #495056;
}
.footer .footer-social .tooltip-inner {
    background-color: #495056;
}
.footer .widget .widget-title {
    margin-bottom: 30px;
    padding-bottom: 10px;
    color: #fff;
    /*font-family: Merriweather;*/
    /*font-weight: 400;*/
    font-size: 18px;
    letter-spacing: 0;
    position: relative;
}
.footer .widget .widget-title:after {
    content: '';
    width: 30px;
    height: 1px;
    background-color: #495056;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.footer .widget ul {
    list-style: none;
    padding: 0;
}
.footer .widget ul li {
    margin-bottom: 15px;
    line-height: 1.6;
}
.footer .widget ul li a {
    position: relative;
    color: #ababab;
}
.footer .widget ul li a:hover {
    padding-left: 15px;
    color: #495056;
}
.footer .widget ul li a:hover:before {
    position: absolute;
    content: '\f462';
    font-family: 'Ionicons';
    left: 0;
    top: 0;
    color: #495056;
    line-height: 18px;
}
.footer .textwidget p {
    padding: 0;
    margin: 0 0 15px 0;
}
.footer .footer-gmap {
    margin-top: 35px;
}
.footer .footer-gmap a {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 400;
}
.footer .footer-gmap a:before {
    content: '\f041';
    font-family: FontAwesome;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    color: #ababab;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 2px solid #595959;
    margin-right: 10px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.copyright {
    display: block;
    width: 100%;
    position: relative;
    padding-top:20px;
	/*background: #333;*/
}
.copyright .copyright-container {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid #2d2d2d;
	float: left;
    width: 100%;
}
.copyright .copyright-left {
    font-size: 14px;
    color: #696969;
    font-family: Lato;
    font-style: italic;
    line-height: 25px;
}
.copyright .copyright-left a {
    color: #fff;
}
.copyright .copyright-left a:hover {
    color: #495056;
}
.copyright .copyright-right {
    text-align: right;
}
.copyright .copyright-right ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.copyright .copyright-right ul li {
    display: inline-block;
    margin-left: 20px;
}
.copyright .copyright-right ul li a {
    text-transform: uppercase;
    font-family: Montserrat;
    color: #696969;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
}
.copyright .copyright-right ul li a:hover {
    color: #495056;
}
.copyright.style-2 .copyright-container {
    border-top: 0;
}
.copyright.style-2 .copyright-left {
    line-height: 55px;
}
.copyright.style-2 .copyright-right ul li a {
    line-height: 55px;
}
@media (min-width: 769px) {
	.copyright.style-2 .copyright-container {
		padding: 120px 100px;
	}
}

/* === 2.12. SERVICES === */
.service-item .icon {
    font-size: 36px;
}
.service-item .title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat;
    text-transform: uppercase;
}
.service-item .links {
    margin-top: 15px;
}
.service-item .links a {
    display: block;
    color: #ababab;
    font-size: 14px;
    line-height: 1;
    margin-top: 15px;
}
.service-item .links a:hover {
    color: #495056;
}
.film-our-services-list .big-title {
    /*font-family: Merriweather;*/
    font-size: 40px;
    /*font-weight: 400;*/
    color: #333;
}
.film-our-services-list .big-title a {
    /*font-family: Merriweather;*/
    font-size: 40px;
    /*font-weight: 400;*/
    color: #333;
    position: relative;
}
.film-our-services-list .big-title a:hover {
    color: #495056;
}
.film-our-services-list .big-title a:after {
    position: absolute;
    right: -130px;
    top: 50%;
    content: '\f30f';
    font-family: Ionicons;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #495056;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
    border: 2px solid #f0f0f0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    transform: translateY(-50%);
}
.film-our-services-list .big-title a:hover:after {
    border-color: #495056;
    background-color: #495056;
    color: #fff;
}
.film-our-services-list .items .item {
    display: block;
    width: 100%;
    margin-top: 40px;
    padding-left: 50px;
    position: relative;
}
.film-our-services-list .items .item .number {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    font-weight: 700;
    font-family: Montserrat;
    color: #495056;
    opacity: .5;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.film-our-services-list .items .item .title {
    display: block;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Montserrat;
    color: #333;
}
.film-our-services-list .items .item .content {
    margin-top: 10px;
    display: block;
}
.film-our-services {
    display: block;
}
.film-our-services.type-icon .col-icon {
    z-index: 97;
}
.film-our-services.type-icon .col-icon .icon {
    width: 100%;
    /*min-height: 255px;*/
    /*background-color: #fff;*/
    color: #495056;
    /*font-size: 100px;*/
    text-align: center;
    -moz-box-shadow: 0 5px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 20px rgba(0,0,0,.1);
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
    margin-top: -50px;
    position: relative;
}
.film-our-services.type-icon .col-icon .icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.film-our-services.type-icon .col-text {
    z-index: 96;
}
.film-our-services.type-icon .col-text .text {
    background-color: #f6f7f7;
    padding: 40px 70px;
    min-height: 270px;
    position: relative;
}
.film-our-services.type-icon .col-text .text:before {
    content: '';
    display: block;
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100px;
    background-color: #f6f7f7;
}
.film-our-services.type-icon .col-text .text .title {
    /*font-family: Merriweather;*/
    /*font-weight: 400;*/
    font-size: 24px;
    color: #333;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-our-services.type-icon:hover .col-text .text .title {
    color: #495056;
}
.film-our-services.type-icon .col-text .text .content {
    font-family: Lato;
    font-size: 15px;
    color: #696969;
    line-height: 1.7;
    display: block;
    margin-top: 20px;
	width: 100%;
}
.film-our-services.type-icon.right .col-icon {
    float: right;
}
.film-our-services.type-icon.right .col-icon .icon {
    margin-top: 50px;
}
.film-our-services.type-icon.right .col-text .text {
    padding: 40px 70px 40px 100px;
}
.film-our-services.right .col-text .text:before {
    left: auto;
    right: -100px;
}
.film-our-services.type-icon .col-icon .icon.primary-color {
    background-color: #495056;
    color: #fff;
}
.film-our-services.type-icon .col-icon .icon.black-color {
    background-color: #333;
    color: #495056;
}
.film-our-services.type-image {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-our-services.type-image:hover {
    -moz-box-shadow: 0 5px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 5px 20px rgba(0,0,0,.1);
    box-shadow: 0 5px 20px rgba(0,0,0,.1);
}
.film-our-services.type-image .col-image {
    z-index: 96;
    padding-right: 0;
}
.film-our-services.type-image .col-image img {
    width: 100%;
    height: auto;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.film-our-services.type-image:hover .col-image img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.film-our-services.type-image .col-text {
    z-index: 97;
}
.film-our-services.type-image .col-text .text {
    background-color: #fff;
    padding: 40px;
}
.film-our-services.type-image .col-text .text .title {
    /*font-family: Merriweather;*/
    /*font-weight: 400;*/
    font-size: 24px;
    color: #333;
    position: relative;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-our-services.type-image:hover .col-text .text .title {
    color: #495056;
}
.film-our-services.type-image .col-text .text .title:before {
    content: '';
    width: 60px;
    height: 1px;
    background-color: #495056;
    display: block;
    position: absolute;
    top: 50%;
    left: -85px;
}
.film-our-services.type-image .col-text .text .content {
    font-family: Lato;
    font-size: 15px;
    color: #696969;
    line-height: 1.7;
    display: block;
    margin-top: 20px;
	width: 100%;
}
.film-our-services.type-image.right .col-image {
    float: right;
    padding-right: 15px;
    padding-left: 0;
}
.film-our-services.type-image.right .col-text {
    text-align: right;
}
.film-our-services.type-image.right .col-text .title:before {
    left: auto;
    right: -85px;
}

/* === 2.13. FILM PROJECTS === */
.film-filter {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
.film-filter ul {
    margin: 0;
    padding: 0;
    transform: translate(-100%,-140px);
    padding-left: 0;
}
.film-filter ul li {
    display: inline;
}
.film-filter ul li a {
    text-transform: uppercase;
    font-size: 13.5px;
    letter-spacing: .05em;
    padding: 7px 20px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
}
.film-filter ul li a.active,
.film-filter ul li a:hover {
    color: #495056;
    border-color: #495056;
}
.film-masonry-item {
	margin-bottom: 30px;
}
.film-masonry-item .film-masonry-item-inner {
    display: block;
    width: 100%;
    background-color: #fff;
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.05);
}
.film-masonry-item .film-masonry-item-inner .thumb {
    overflow: hidden;
}
.film-masonry-item .film-masonry-item-inner .thumb img {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-height: 300px;
    /*-webkit-filter: grayscale(100%);*/
    /*filter: grayscale(100%);*/
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    margin:auto;
}
.film-masonry-item .film-masonry-item-inner:hover .thumb img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    transform: scale(1.1);
}
.film-masonry-item .film-masonry-item-inner .info {
    padding: 20px 30px;
}
.film-masonry-item .film-masonry-item-inner .info .title a {
    font-size: 18px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #333;
}
.film-masonry-item .film-masonry-item-inner .info .title a:hover {
    color: #495056;
}
.film-masonry-item .film-masonry-item-inner .info .category a {
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #ccc;
    display: inline-block;
    margin-right: 20px;
}
.film-masonry-item .film-masonry-item-inner .info .category a:hover {
    color: #495056;
}
.film-masonry-item .film-masonry-item-inner .info .meta {
    margin-top: 20px;
    font-size: 14px;
    color: #696969;
}
.film-masonry-item .film-masonry-item-inner .info .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-masonry-item .film-masonry-item-inner .info .meta > span:last-child {
    margin-right: 0;
}
.film-masonry-item .film-masonry-item-inner .info .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-masonry-item .film-masonry-item-inner .info .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-list-item {
	display: block;
	clear: both;
}
.film-list-item .col-md-6 {
    padding: 0;
}
.film-list-item:nth-child(odd) .col-thumb {
    padding-right: 0;
}
.film-list-item .col-thumb .thumb {
    width: 100%;
    overflow: hidden;
}
.film-list-item .col-thumb .thumb img {
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-list-item:hover .col-thumb .thumb img {
    transform: scale(1.1);
}
.film-list-item .col-info .info {
    padding: 50px 30px;
}
.film-list-item:nth-child(even) .col-info .info {
    text-align: right;
}
.film-list-item .col-info .info .title {
    font-size: 24px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #333;
    display: block;
    position: relative;
}
.film-list-item .col-info .info .title a {
    color: #333;
}
.film-list-item .col-info .info .title a:hover {
    color: #495056;
}
.film-list-item:nth-child(odd) .col-info .info .title:before {
    content: '';
    width: 60px;
    height: 1px;
    display: block;
    background-color: #495056;
    position: absolute;
    top: 50%;
    left: -80px;
}
.film-list-item:nth-child(even) .col-info .info .title:after {
    content: '';
    width: 60px;
    height: 1px;
    display: block;
    background-color: #495056;
    position: absolute;
    top: 50%;
    right: -80px;
}
.film-list-item .col-info .info .category a {
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #ccc;
    display: inline-block;
    margin-right: 20px;
}
.film-list-item .col-info .info .category a:hover {
    color: #495056;
}
.film-list-item .col-info .info .excerpt {
    display: block;
    margin-top: 20px;
}
.film-list-item .col-info .info .meta {
    margin-top: 20px;
    font-size: 14px;
    color: #696969;
}
.film-list-item .col-info .info .meta > span a {
    color: #696969;
}
.film-list-item .col-info .info .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-list-item .col-info .info .meta > span:last-child {
    margin-right: 0;
}
.film-list-item .col-info .info .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-list-item .col-info .info .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.film-list-item .col-info .info .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-post-slider .item {
    height: 700px;
    background-size: cover;
    background-position: center;
}
.film-post-slider .item .container,
.film-post-slider .item .row {
    height: 100%;
}
.film-post-slider .item .row {
    align-items: flex-end;
    align-content: flex-end;
    align-self: flex-end;
	display: flex;
    flex-wrap: wrap;
    margin-left: -.9375rem;
    margin-right: -.9375rem;
}
.film-post-slider .item .row .blog-classic-style {
    margin-bottom: 50px;
    padding-bottom: 50px;
	color: #fff;
}
.film-post-slider .item .row .blog-classic-style .film-list-categories {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.film-post-slider .film-list-categories a {
    font-size: 12px;
    color: #fff;
    letter-spacing: .1em;
    background-color: #495056;
    display: inline-block;
    width: auto;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
}
.film-post-slider .blog-classic-style .entry-desc {
    padding-top: 28px;
}
.film-post-slider .blog-classic-style .entry-title {
    margin-bottom: 10px;
    position: relative;
	color: #fff;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
}
.film-post-slider .blog-classic-style .meta {
    margin-top: 20px;
}
.film-post-slider .blog-classic-style .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-post-slider .blog-classic-style .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-post-slider .blog-classic-style .meta > span.like a {
	color: #FFF;
}
.film-post-slider .blog-classic-style .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.film-post-slider .blog-classic-style .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-post-slider .blog-classic-style .entry-content {
    font-size: 15px;
    color: #fff;
    line-height: 1.6;
}
.film-post-slider .slider-nav-container {
    position: absolute;
    bottom: 100px;
    right: 100px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.film-post-slider .slider-nav-container li {
    padding: 30px 0;
    border-bottom: 1px solid rgba(255,255,255,.3);
    cursor: pointer;
}
.film-post-slider .slider-nav-container li:first-child {
    display: none; 
}
.film-post-slider .slider-nav-container li * {
    color: #fff;
}
.film-post-slider .slider-nav-container li .thumb, 
.film-post-slider .slider-nav-container li .desc {
    display: inline-block;
    vertical-align: middle;
}
.film-post-slider .slider-nav-container li .thumb {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    margin-right: 25px;
    max-width: 120px;
}
.film-post-slider .slider-nav-container li:hover .thumb {
    transform: scale(1.2);
}
.film-post-slider .slider-nav-container li .desc {
    width: 220px;
}
.film-post-slider .slider-nav-container li .desc .slider-nav-cats {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .1em;
}
.film-post-slider .slider-nav-container li .desc h6 {
    font-size: 14px;
    line-height: 24px;
}
.film-detail-video {
	overflow: hidden;
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
}
.film-detail-video.full {
	margin-right: 100px;
    margin-left: 100px;
}
.film-detail-video a {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}
.film-detail-video a:before {
    content: '';
    width: 100px;
    height: 100px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: #495056;
    opacity: .3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 97;
}
.film-detail-video a:after {
    content: '\f215';
    font-family: Ionicons;
    color: #fff;
    font-size: 24px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    background-color: #495056;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 98;
}
.film-detail-video a img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    z-index: 96;
}
.film-detail-video:hover img {
    transform: scale(1.1);
}
.film-detail-title .category a {
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #ccc;
    display: inline-block;
    margin-right: 20px;
}
.film-detail-title .meta {
    margin-top: 20px;
	margin-bottom: 15px;
    color: #696969;
}
.film-detail-title .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-detail-title .meta > span a {
    color: #696969;
}
.film-detail-title .meta > span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-detail-title .meta > span.like a:before {
    content: '\f387';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
    display: inline-block;
}
.film-detail-title .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-detail-content {
	margin-bottom: 35px;
}
.film-detail-info {
    display: block;
    width: 100%;
    padding: 50px 30px;
    background-color: #f6f7f7;
}
.film-detail-info .info-item {
    display: block;
    margin-bottom: 40px;
}
.film-detail-info .info-item .title {
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.film-detail-info .info-item .content {
    font-size: 14px;
    color: #696969;
	width: 100%;
}
.film-detail-info.film-detail-info-carousel {
    background: none;
}
.film-detail-info.film-detail-info-carousel .info-item {
    display: block;
    padding: 0 15px;
    outline: 0;
}
.film-detail-info.film-detail-info-carousel .info-item .info-item-inner {
    padding: 30px;
    background-color: #f6f7f7;
}
.relate-film-carousel .item {
    padding: 0 15px 20px;
    outline: 0;
}
.relate-film-carousel .item .item-inner {
    display: block;
    width: 100%;
    background-color: transparent;
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.05);
}
.relate-film-carousel .item .item-inner .thumb {
    overflow: hidden;
}
.relate-film-carousel .item .item-inner .thumb img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.relate-film-carousel .item .item-inner:hover .thumb img {
    transform: scale(1.1);
}
.relate-film-carousel .item .item-inner .info {
    padding: 15px 30px;
}
.relate-film-carousel .item .item-inner .info .title a {
    font-size: 18px;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
    color: #333;
}
.relate-film-carousel .item .item-inner .info .title a:hover {
	color: #495056;
}
.relate-film-carousel .item .item-inner .info .category a {
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat;
    text-transform: uppercase;
    color: #ccc;
    display: inline-block;
    margin-right: 20px;
}
.relate-film-carousel .item .item-inner .info .category a:hover {
    color: #495056;
}

/* === 2.14. COUNTER === */
.counter-digit {
    font-size: 40px;
    color: #495056;
    line-height: 1;
    text-align: left;
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
    font-weight: 700;
    text-transform: none;
	margin-bottom: 20px;
}
.counter-title {
    font-size: 14px;
    color: #333333;
    line-height: 1;
    text-align: left;
    font-family: Montserrat;
    font-weight: 700;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
	margin-bottom: 20px;
}
.counter-desc {
    font-size: 15px;
    color: #696969;
    line-height: 26px;
    text-align: left;
    font-weight: 400;
    text-transform: none;
}

/* === 2.15. TEAM === */
.team-carousel-wrap {
	padding: 50px 100px;
}
.team-item {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
	outline: 0;
    padding: 30px 15px;
}
.team-item .thumb {
    overflow: hidden;
}
.team-item .thumb img {
    width: 100%;
    height: auto;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.team-item:hover .thumb img {
    transform: scale(1.1);
}
.team-item .info {
    background-color: #fff;
    text-align: center;
    padding: 25px 10px;
}
.team-item .info .name {
    font-size: 18px;
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
}
.team-item .info .name a {
    font-size: 18px;
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
}
.team-item .info .name a:hover {
    color: #495056;
}
.team-item .info .tagline {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Montserrat;
    color: #ccc;
    line-height: 28px;
    height: 28px;
    overflow: hidden;
}
.team-item:hover .info .tagline {
    display: none;
}
.team-item .info .socials {
    font-size: 14px;
    font-weight: 400;
    font-family: Lato;
    color: #ccc;
    line-height: 28px;
    height: 28px;
    overflow: hidden;
}
.team-item .info .socials {
    display: none;
}
.team-item:hover .info .socials {
    display: block;
}
.film-list-member {
	margin-bottom: 30px;
}
.film-list-member .item {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
}
.film-list-member .item .info {
    margin-top: 35px;
    margin-bottom: 20px;
}
.film-list-member .item .name {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    position: relative;
    display: inline-block;
}
.film-list-member .item .tagline {
    font-size: 12px;
    color: #ccc;
    letter-spacing: .1em;
    display: block;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.team-filter-scroll {
	overflow: hidden;
}
.team-filter ul {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}
.team-filter ul li {
    list-style: none;
    display: inline-block;
    padding-right: 50px;
}
.team-filter ul li a {
    cursor: pointer;
    text-transform: lowercase;
    color: #333;
    font-weight: 700;
    /*font-family: Merriweather;*/
    font-size: 56px;
    opacity: .2;
}
.team-filter ul li a.active {
    opacity: 1;
}
.film-member-info .member-info {
    display: table;
}
.film-member-info .member-info .line {
    display: table-row;
}
.film-member-info .member-info .line .name {
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
    display: table-cell;
    padding: 10px 0;
}
.film-member-info .member-info .line .value {
    display: table-cell;
    padding: 10px 0 10px 40px;
}
.film-member-info .member-info .line .value.socials a {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #cdcdcd;
    color: #cdcdcd;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    margin-right: 10px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.film-member-info .member-info .line .value.socials a:hover {
    background-color: #495056;
    border-color: #495056;
    color: #fff;
}
.film-member-skill .title {
    font-size: 40px;
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
    line-height: 1.2em;
    margin-bottom: 40px;
    position: relative;
}
.film-member-skill .title:before {
    content: '';
    width: 10px;
    height: 20px;
    background-color: #495056;
    position: absolute;
    left: -40px;
    top: -20px;
    -ms-transform: matrix(1,.45,0,1,0,0);
    -webkit-transform: matrix(1,.45,0,1,0,0);
    transform: matrix(1,.45,0,1,0,0);
}
@media (min-width: 769px) {
	.film-list-member .item .info {
		position: absolute;
		top: 0;
		left: 100%;
		white-space: nowrap;
		margin-left: 50px;
	}
	.film-list-member .item .name:after {
		position: absolute;
		content: '';
		display: block;
		width: 60px;
		height: 2px;
		background-color: #495056;
		top: 50%;
		margin-top: -2px;
		left: -80px;
	}
	.film-list-member .item:nth-child(even) {
		margin-top: 110px;
	}
	.film-list-member .item:nth-child(even) .info {
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 100%;
		white-space: nowrap;
		margin-right: 50px;
		left: auto;
		top: auto;
	}
	.film-list-member .item:nth-child(even) .name:after {
		left: auto;
		right: -80px;
	}
}

/* === 2.16. FILM MENU === */
.film-menu-add-param ul {
    margin: 0 auto;
    padding: 0;
    display: block;
}
.film-menu-add-param ul li {
    list-style: none;
    display: inline-block;
    position: relative;
    padding: 0;
	float: left;
	width: 100%;
}
.film-menu-add-param ul li a {
    padding-top: 25px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: .4em;
    color: #050505;
    box-sizing: border-box;
    height: 110px;
    display: block;
    position: relative;
    z-index: 2;
    border-top: 2px solid #252525;
}
.film-menu-add-param ul li a i {
    font-size: 30px;
    color: #050505;
}
.film-menu-add-param ul li a span {
    margin-top: 2px;
    display: block;
}
.film-menu-add-param ul li a:hover {
    background: #252525;
    border-color: #495056;
    background-color: #495056;
}
.film-menu-add-param ul li a:hover, 
.film-menu-add-param ul li a:hover i {
    color: #fff;
}
.film-menu-add-param ul li:after {
    content: '';
    display: block;
    width: 1px;
    height: 60px;
    background: rgba(105,105,105,.3);
    position: absolute;
    left: -1px;
    top: 25px;
    z-index: 1;
}
.film-menu-add-param ul li:first-child:after {
    display: none;
}
@media (min-width: 769px) {
	.film-menu-add-param ul li {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
}

/* === 2.17. BLOGGER === */
.blogger-template .main {
    padding: 100px;
}
.blogger-template .sidebar {
    background-color: #f8f8f8;
    padding: 0 40px 100px 40px;
    overflow: hidden;
}
.blogger-template .sidebar .widget .film-social li a {
    border: 2px solid #f0f0f0;
}
.blogger-template .sidebar .widget .film-social li a:hover {
    color: #fff;
    background-color: #495056;
    border-color: #495056;
}
.blogger-template .sidebar .widget .widget-title,
.blogger-template .sidebar a {
    color: #333;
    /*font-weight: 400;*/
    /*font-family: Merriweather;*/
}
.blogger-template .sidebar .widget .widget-title {
    letter-spacing: .05em;
    position: relative;
    padding-top: 15px;
    display: inline-block;
    clear: both;
    font-size: 24px;
    margin-top: 20px;
}
.blogger-template .sidebar .widget .widget-title:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background-color: #e0e0e0;
    left: -40px;
    right: -1000px;
    top: 0;
}
.blogger-template .sidebar .widget .widget-title:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background-color: #495056;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
}
.blogger-template .sidebar .widget.widget-tag-cloud a {
    background: rgba(51,51,51,.1);
    border-color: rgba(51,51,51,.1);
    color: #ababab;
}
.blogger-template .sidebar .widget.widget-tag-cloud a:hover {
    border-color: #333;
    color: #333;
}

/* === 2.18. SIDEBAR === */
.widget {
    margin: 0 0 50px 0;
}
.widget .widget-title {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
    /*font-family: Merriweather;*/
}
.widget-area .widget .widget-title:after {
    content: '';
    display: block;
    width: 30px;
    height: 1px;
    background-color: #495056;
    margin-top: 5px;
}
.widget ul {
    margin: 0;
    padding: 0;
}
.sidebar .widget ul li {
    margin: 0 10px 0 0;
    list-style: none;
    padding: 15px 0;
	display: inline-block;
    border-bottom: 1px solid #f4f4f4;
}
.sidebar .widget ul li:first-child {
    padding-top: 0;
}
.widget .film-aboutme .avatar img {
    width: 100%;
}
.widget .film-aboutme .about-title {
    font-size: 56px;
    color: #495056;
    text-transform: uppercase;
    letter-spacing: .1em;
    line-height: .8;
    margin-top: -150px;
}
.widget .film-aboutme .desc {
    margin-bottom: 10px;
}
.widget .film-aboutme .desc, 
.widget .film-aboutme .desc * {
    color: #696969;
}
.widget .film-aboutme .desc h6 {
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-top: 20px;
    margin-bottom: 20px;
}
.widget.widget-film-post .item {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.widget.widget-film-post .item .thumb {
    max-width: 120px;
    float: left;
    margin-right: 20px;
}
.widget.widget-film-post .item .info {
    line-height: 1.6em;
    margin-top: -7px;
}
.widget.widget-film-post .item .info .date {
    display: block;
    font-size: 12px;
    color: #ccc;
    text-transform: uppercase;
    letter-spacing: .1em;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.widget.widget-film-post .item .info .title {
    display: block;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.widget.widget-tag-cloud a {
    padding: 8px 15px;
    background: #f6f7f7;
    border: 1px solid #f6f7f7;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 14px;
    display: inline-block;
    color: #ababab;
    margin-right: 5px;
    margin-bottom: 7px;
    line-height: 1;
    font-family: Montserrat;
}
.widget.widget-tag-cloud a:hover {
    border-color: #495056;
    background-color: #495056;
    color: #fff;
}
.widget-area .widget.widget-search {
	position: relative;
}
.widget-area .widget.widget-search .search-form input[type="search"],
.widget-area .widget.widget-search .search-form input[type="text"] {
    width: 100%;
    height: 40px;
    line-height: 38px;
    border: 1px solid #f4f4f4;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    outline: 0;
    padding: 0 15px;
    font-size: 15px;
    font-weight: 400;
    box-sizing: border-box;
	background-color: #f4f4f4;
}
.widget-area .widget.widget-search .search-form input[type="search"]:focus,
.widget-area .widget.widget-search .search-form input[type="text"]:focus {
    border: 1px solid #495056;
}
.widget-area .widget.widget-search .search-form input[type="submit"] {
    position: absolute;
    height: 40px;
    width: 40px;
    background-image: url(../images/search.png);
    background-repeat: no-repeat;
    background-color: transparent;
    box-shadow: none;
    font-size: 0;
    border: 0;
    line-height: 40px;
    padding: 0;
    top: 0;
    right: 0;
}
.widget-area .widget.widget-film-categories .item {
    margin-bottom: 25px;
    position: relative;
    width: 100%;
}
.widget-area .widget.widget-film-categories .item a {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-left: 20px;
    z-index: 98;
    font-size: 14px;
}
.widget-area .widget.widget-film-categories .item a:before {
    content: "\f111";
    font-family: FontAwesome;
    font-size: 6px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #e2e2e2;
}
.widget-area .widget.widget-film-categories .item a:after {
    content: "";
    display: inline-block;
    height: .5em;
    vertical-align: bottom;
    width: 100%;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 1px solid #f4f4f4;
    z-index: 98;
}
.widget-area .widget.widget-film-categories .item span {
    position: absolute;
    right: 0;
    top: -4px;
    height: 22px;
    background-color: #fff;
    z-index: 99;
    display: block;
    text-align: right;
    color: #696969;
    border-radius: 50%;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    text-align: center;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.widget-area .widget.widget-film-posts .item {
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.widget-area .widget.widget-film-posts .item .thumb {
    max-width: 120px;
    float: left;
    margin-right: 20px;
}
.widget-area .widget.widget-film-posts .item .info {
    line-height: 1.6em;
    margin-top: -7px;
}
.widget-area .widget.widget-film-posts .item .info .date {
    display: block;
    font-size: 12px;
    color: #ccc;
    text-transform: uppercase;
    letter-spacing: .1em;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.widget-area .widget.widget-film-posts .item .info .title {
    display: block;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}

/* === 2.19. FILM ONEPAGE === */
.film-one-page {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding: 0;
    -webkit-transform-style: preserve-3d;
	transform: translate3d(0px, 0%, 0px);
    transition: all 1000ms ease;
}
.film-one-page .film-one-page-item {
    padding: 0px 50px 80px 50px;
	width: 100%;
    height: 100%;
    overflow: hidden;
    min-height:600px;
}
.film-one-page .film-one-page-item:first-child {
    padding-top: 100px;
}
.film-one-page .film-one-page-item .film-one-page-item-inner {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
	background-image: url(../images/background/bg_10.jpg);
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text {
    position: absolute;
	top: 50%;
    left: 45%;
    z-index: 96;
    transform: translate(-50%,-50%);
    z-index: 97;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .time {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #696969;
    text-transform: uppercase;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .title {
    font-size: 80px;
    font-weight: 700;
    /*font-family: Merriweather;*/
    color: #fff;
    line-height: 90px;
    margin-top: 20px;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta {
    margin-top: 20px;
    font-size: 14px;
    color: #ababab;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta > span {
    display: inline-block;
    margin-right: 20px;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta > span:last-child {
    margin-right: 0;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta>span.view:before {
    content: '\f133';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta > span.comment:before {
    content: '\f11a';
    font-family: Ionicons;
    font-size: 16px;
    color: #495056;
    margin-right: 10px;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video {
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 96;
    transform: translate(0%,-50%);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,.1);
    box-shadow: 0 10px 20px rgba(0,0,0,.1);
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner {
    position: relative;
    overflow: hidden;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play {
    z-index: 97;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play:before {
    content: '';
    width: 70px;
    height: 70px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    background-color: #495056;
    opacity: .3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 97;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play:after {
    content: '\f215';
    font-family: Ionicons;
    color: #fff;
    font-size: 18px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    background-color: #495056;
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 98;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner img {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}
.film-one-page .film-one-page-item .film-one-page-item-inner .video:hover .video-inner img {
    transform: scale(1.1);
}
.onepage-pagination {
    position: fixed;
    left: 15px;
    top: 50%;
    z-index: 5;
    list-style: none;
    margin: 0;
    padding: 0;
}
.onepage-pagination li {
    padding: 0;
    text-align: center;
}
.onepage-pagination li a {
    display: block;
    height: 40px;
    line-height: 40px;
    position: relative;
}
.onepage-pagination li a:before {
    content: "0" attr(data-index);
    font-family: 'Montserrat',Tahoma,Verdana;
    font-size: 12px;
    font-weight: 700;
    color: #ababab;
    -webkit-transition: all linear .5s;
    -moz-transition: all linear .5s;
    -ms-transition: all linear .5s;
    -o-transition: all linear .5s;
    transition: all linear .5s;
}
.onepage-pagination li a:after {
    content: '';
    width: 0;
    height: 1px;
    display: block;
    background-color: #495056;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: width linear .5s;
    -moz-transition: width linear .5s;
    -ms-transition: width linear .5s;
    -o-transition: width linear .5s;
    transition: width linear .5s;
}
.onepage-pagination li a.current:before {
    font-size: 18px;
    color: #333;
}
.onepage-pagination li a.current:after {
    width: 80px;
}

/* === 2.20. PAGE TITLE === */
.page-title {
    padding-top: 145px;
    padding-bottom: 125px;
    background-color: #232a32;
    /*background-color: rgba(73, 80, 86, .1);*/
    /*background-color: #f6f7f7;*/
    /*background-image: url(../images/background/bg_1920x370.jpg);*/
	color: #333;
    text-align: left;
	background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    display: block;
}
.page-title.big-style {
    padding-top: 230px;
    padding-bottom: 330px;
	background-image: url(../images/background/bg_1920x670.jpg);
}
.page-title.big-style-bg-2 {
    background-image: url(../images/background/bg_1920x670.jpg);
}
.page-title .title {
    /*font-family: Merriweather,Georgia,serif;*/
    font-weight: 400;
    font-style: normal;
    font-size: 56px;
    line-height: 1;
    letter-spacing: 0;
    color: #fff;
    /*color: #333;*/
	display: block;
    position: relative;
	z-index: 97;
    margin-top: 0;
}
.page-title .title span {
    color: #495056;
}
.breadcrumbs {
    padding: 0;
    margin-top: 30px;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .2em;
    line-height: 1;
}
.page-title .breadcrumbs {
    display: block;
    z-index: 98;
}
.breadcrumbs ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.breadcrumbs ul li {
    display: inline-block;
    max-width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.breadcrumbs ul li:last-child {
    color: #495056;
    border-bottom: 1px solid #495056;
    margin-right: 0;
}
.breadcrumbs ul li a {
    color: #ababab;
}
.breadcrumbs ul li:after {
    content: '/';
    color: #ababab;
    margin-left: 10px;
}
.breadcrumbs ul li:last-child:after {
    content: '';
    margin-left: 0;
}

/* === 2.21. GALLERY === */
.gallery-filter {
    font-family: Montserrat,Helvetica,Arial,sans-serif;
	margin-top: 32px;
    margin-bottom: 57px;
    text-align: left;
}
.gallery-filter ul li {
    display: inline;
}
.gallery-filter ul li a {
    text-transform: uppercase;
    font-size: 13.5px;
    letter-spacing: .05em;
    padding: 7px 20px;
    font-weight: bold;
    border: 1px solid transparent;
}
.gallery-filter ul li a:hover,
.gallery-filter ul li a.active {
    color: #495056;
    border-color: #495056;
}
.gallery-filter ul li:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 1px;
    width: 10px;
}
.gallery-filter ul li:first-child:before {
    width: 0px;
}
.gallery-masonry-item {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}
.gallery-masonry-item .gallery-image {
    position: relative;
    max-height: 100%;
}
.gallery-masonry-item .gallery-image img {
    width: 100%;
}
.gallery-masonry-item .gallery-image .desc-wrap {
    -webkit-transition: opacity .25s;
    -moz-transition: opacity .25s;
    -ms-transition: opacity .25s;
    -o-transition: opacity .25s;
    transition: opacity .25s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #495056;
    display: flex;
    align-items: flex-end;
}
.gallery-masonry-item .gallery-image a:hover .desc-wrap {
    opacity: 1;
}
.gallery-masonry-item .gallery-image .desc-wrap:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #262626;
    opacity: .8;
}
.gallery-masonry-item .gallery-image .desc-wrap .desc {
    width: 100%;
    padding-bottom: 60px;
    padding-left: 25px;
    padding-right: 25px;
}
.gallery-masonry-item .gallery-image .desc-wrap .title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    display: block;
	font-family: Merriweather,Georgia,serif;
}
.gallery-masonry-item .gallery-image .desc-wrap .title:after {
    content: "\f30f";
    font-family: "Ionicons";
    font-size: 18px;
    font-weight: 400;
    height: 40px;
    width: 40px;
    display: inline-block;
    border: 2px solid rgba(255,255,255,.7);
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    float: right;
    margin-top: 4px;
    color: #495056;
}
.gallery-masonry-item .gallery-image .desc-wrap .cates {
    font-size: 12px;
    color: #fff;
    letter-spacing: .1em;
    text-transform: uppercase;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.gallery-masonry-item .gallery-image .desc-wrap .title, 
.gallery-masonry-item .gallery-image .desc-wrap .cates, 
.gallery-masonry-item .gallery-image .desc-wrap .icon {
    position: relative;
    z-index: 2;
}

/* === 2.22. 404 === */
.content-404 {
    text-align: center;
    padding: 60px 0;
    z-index: 97;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.content-404 h3 {
    font-size: 24px;
    color: #333;
    font-family: Montserrat;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .4em;
    text-align: center;
}
.content-404 img {
    margin: 30px 0;
}
.content-404 span {
    font-size: 14px;
    color: #696969;
    font-family: Montserrat;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .1em;
    display: block;
}
.content-404 span a {
    font-weight: 700;
    padding-bottom: 2px;
    border-bottom: 1px solid #333;
}
.content-404 span a:hover {
    border-color: #495056;
	color: #495056;
}
.contact-404 {
    width: 360px;
    height: 189px;
    display: block;
    font-size: 24px;
    font-weight: 700;
    padding-top: 120px;
    padding-left: 40px;
    line-height: 1;
    color: #495056;
    font-family: Montserrat;
    background-image: url(../images/background/404_contact_bg.png);
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 98;
}
.follow-404 {
    height: 24px;
    line-height: 24px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: fixed;
    top: 50%;
    right: -120px;
}
.follow-404 .follow-404-socials {
    display: inline-block;
    float: left;
}
.follow-404 .follow-404-socials a {
    display: inline-block;
    font-size: 20px;
    color: #ababab;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-right: 30px;
}
.follow-404 .follow-404-socials a:hover {
    color: #495056;
}
.follow-404 .follow-404-text {
    line-height: 24px;
    white-space: normal;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    font-family: Montserrat;
    color: #333;
    display: inline-block;
    margin-left: 30px;
}
.follow-404 .follow-404-text:before {
    content: '';
    width: 60px;
    height: 1px;
    display: inline-block;
    background-color: #d0d1d1;
    margin-right: 20px;
    vertical-align: middle;
}

/* === 2.23. CONTACT === */
.contact-info .icon-boxes {
    margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #f4f4f4;
}
.contact-info .icon-boxes .icon-boxes-inner {
    display: table-cell;
    vertical-align: top;
}
.contact-info .icon-boxes .icon-boxes-title {
    margin-top: 0;
	margin-bottom: 10px;
    letter-spacing: .05em;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
	font-family: Montserrat,Helvetica,Arial,sans-serif;
}
.contact-info .icon-boxes .icon-boxes-content {
    color: #696969;
}

/* === 2.24. COMMENT === */
.comments-area .comments-title {
    /*font-family: Merriweather;*/
    font-size: 24px;
    color: #333;
    margin-bottom: 30px;
    font-weight: bold;
}
.comments-area .comments-title:after {
    content: '';
    display: block;
    width: 30px;
    height: 1px;
    background-color: #495056;
    margin-top: 5px;
}
.comments-area .comment-list {
    padding-left: 0;
    list-style: none;
    margin: 40px 0;
}
.comments-area .comment-list li {
    padding: 20px 0;
	list-style-type: none;
}
.comments-area .comment-list li:last-child {
    border-bottom: 0;
}
.comments-area .comment-list li article {
    margin: 0;
    padding-left: 90px;
    position: relative;
}
.comments-area .comment-list li article footer {
    display: block;
}
.comments-area .comment-list li article .comment-author {
    display: inline-block;
    color: #333;
}
.comments-area .comment-list li article .comment-author img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}
.comments-area .comment-list li article .comment-author .fn {
    letter-spacing: .05em;
    font-size: 16px;
    text-transform: capitalize;
}
.comments-area .comment-list li article .comment-metadata {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
    color: #ababab;
}
.comments-area .comment-list li article .comment-metadata:before {
    content: '\f21b';
    font-family: Ionicons;
    color: #e0e0e0;
    font-style: normal;
    font-size: 5px;
    margin-right: 20px;
}
.comments-area .comment-list li article .comment-metadata a {
    color: #8e8b87;
}
.comments-area .comment-list li article .comment-content {
    font-size: 15px;
    line-height: 1.6;
    color: #696969;
}
.comments-area .comment-list li article .reply {
    margin-top: -8px;
}
.comments-area .comment-list li article .reply a {
    color: #ccc;
    font-family: Montserrat;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .05em;
    font-size: 12px;
}
.comments-area .comment-list li article .reply a:before {
    content: '\f21e';
    font-family: Ionicons;
    margin-right: 5px;
    font-size: 16px;
}
.comments-area .comment-list li .children {
    margin-top: 40px;
    padding-left: 0;
}
.comments-area .comment-list li .children li {
    margin-top: 30px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    border-bottom: 0;
    border-left: 1px solid #ccc;
}
.comments-area .comment-list li .children li:last-child {
    padding-bottom: 0;
}

@media (min-width: 769px) {
	.comments-area .comment-list li .children {
		padding-left: 100px;
	}
}

/* === 2.25. PROGRESS BARS === */
.progress-wraper .progress-title {
	display: block;
    font-size: 12px;
    font-family: Montserrat;
    color: #ababab;
    text-transform: uppercase;
	font-weight: normal;
}
.progress-wraper .progress-value {
    color: #878787;
    padding-right: 15px;
}
.progress-wraper .block-progressbar {
    position: relative;
	width: 100%;
	margin-bottom: 35px;
}
.progress-wraper .block-progressbar:last-child {
	margin-bottom: 0px;
}
.block-progressbar .progressbar-back-text {
    position: absolute;
    background-color: #e0e0e0;
	width: 100%;
    height: 8px;
    border-radius: 0;
	font-size: 0;
	text-indent: -9999px;
	-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.block-progressbar .progresswrap {
	height: 8px;
}
.block-progressbar .progressbar {
	position: absolute;
	background-color: #495056;
	height: 8px;
	width: 0;
	-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
	transition: all 1s linear;
	-moz-transition: all 1s linear;
	-webkit-transition: all 1s linear;
	-o-transition: all 1s linear;
}
.block-progressbar .progressbar-front-text {
    position: absolute;
	right: 0px;
	top: -30px;
	width: 35px !important;
    height: 20px;
    line-height: 20px;
    color: #FFF;
    background: #222;
    text-align: center;
    font-size: 12px;
    margin-bottom: 6px;
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}
.block-progressbar .progressbar-front-text:after {
    content: "";
    position: absolute;
    border-top: 5px solid #222;
    border-left: 12px solid transparent;
    bottom: -4px;
    right: 0;
}


/* 4. RESPONSIVE
/* --------------------------------------------------------------------- */
@media (max-width: 1175px) {
	header.header-desktop {
		display: none !important;
	}
	header.header-mobile {
		display: block;
	}
}
@media (max-width: 768px) {
	.header.header-mobile .container {
		padding-left: 45px;
		padding-right: 45px;
		max-width: 576px;
	}
	.text-center-sm {
		text-align: center;
	}
	.text-left-sm {
		text-align: left;
	}
	.popup-video {
		margin-top: 0;
		width: 100%;
	}
	.copyright .copyright-right {
		text-align: left;
	}
	.copyright .copyright-right.text-center-sm {
		text-align: center;
	}
	.copyright .copyright-right ul li:first-child {
		margin-left: 0;
	}
	.featured-title, .text-center .featured-title {
		margin-left: 0;
	}
	.film-filter {
		position: static;
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
		text-align: center;
	}
	.film-filter ul {
		transform: translate(0,0);
		margin-bottom: 50px;
	}
	.film-post-slider .slider-nav-container {
		display: none;
	}
	.film-one-page .film-one-page-item:first-child {
		 padding-top: 0px; 
	}
	.film-list-member {
		text-align: center;
	}
	.page-title.big-style {
		padding-top: 145px;
		padding-bottom: 125px;
		background-color: #f6f7f7;
		background-image: url(../images/background/bg_11.jpg);
	}
	.team-filter ul li a {
		font-size: 16px;
	}
	.team-filter ul li {
		padding-right: 20px;
	}
	.film-hire-box {
		margin-bottom: 80px;
	}
	.film-hire-box.style-2:before {
		background: none;
	}
	.film-hire-box.style-2 .film-hire-box-right {
		background-color: #333;
    	padding: 60px;
    	text-align: center;
	}
	.film-our-services.type-icon.right .col-icon {
		float: none;
	}
	.film-our-services.type-icon .col-icon .icon,
	.film-our-services.type-icon.right .col-icon .icon {
		margin-top: 0;
	}
	.film-our-services.type-icon .col-text .text:before {
		display: none;
	}
	.film-our-services.type-image.right .col-image {
		float: none;
	}
	.gallery-filter {
		text-align: center;
	}
	.gallery-filter ul li a {
		padding: 7px;
	}
	.blog-list-item.style-2 .list-categories {
		margin-top: 4px;
	}
	.sidebar {
		margin-top: 50px;
	}
}
@media (max-width: 667px) {
	
}
@media (max-width: 414px) {
	.text-center-xs {
		text-align: center !important;
	}
	a.backtotop {
		right: 15px;
	}
	a.backtotop.show {
		bottom: 15px;
	}
	.team-carousel-wrap {
		padding: 50px 15px;
	}
	.icon-boxes {
		margin-bottom: 30px;
	}
	.blogger-template .main {
		padding: 0;
	}
	.film-one-page .film-one-page-item {
		padding-left: 0;
		padding-right: 0;
	}
	.film-one-page .film-one-page-item .film-one-page-item-inner .text .title {
		font-size: 60px;
	}
	.onepage-pagination {
		display: none;
	}
	.film-our-services-list .big-title a:after {
		right: -90px;
	}
	.section-bg-11 {
		background: none;
	}
	.section-bg-11 .section-title.white,
	.section-bg-11 .icon-boxes-title.white {
		color: #333333 !important;
	}
	.hashtag {
		font-size: 35px;
		left: 15px;
	}
}

/* Spinner */
.noo-spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.985);
    z-index: 999999;
    -webkit-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}
.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 -20px;
}
.page-framed .spinner {
	margin-left: -50px;
}
@media (max-width: 414px) {
	.page-framed .spinner {
		margin-left: -20px;
	}
	.spinner {
		left: 45%;
	}
}
.page-presentation .spinner {
	margin-left: -50px;
	margin-top: -50px;
}
.spinner .child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #495056;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
            animation: sk-doubleBounce 2s infinite ease-in-out; 
}
.spinner .double-bounce2 {
    -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
}

@-webkit-keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes sk-doubleBounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }